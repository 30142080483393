<template>
  <div>
    <b-card>
      <b-form inline>
        <b-form-group
          label="Time range"
          label-sr-only
        >
          <date-range-picker
            v-model="timeRange"
            :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days', 'last60Days', 'last90Days']"
            :max-selectable-days="90"
            :timezone="asup.timezone"
          />
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            v-model="onlyErrors"
            switch
          >
            Errors only
          </b-form-checkbox>
        </b-form-group>
      </b-form>
    </b-card>

    <b-overlay :show="isJobsLoading">
      <b-card>
        <div class="table-responsive table-bordered">
          <table class="table">
            <thead>
              <tr>
                <th colspan="2">
                  Workflow
                </th>
                <th colspan="4">
                  Last Job
                </th>
                <th colspan="1">
                  Workflow Jobs
                </th>
                <th colspan="1">
                  Clients
                </th>
                <th colspan="1">
                  Clones
                </th>
              </tr>
              <tr>
                <th>Policy</th>
                <th>Workflow</th>
                <th>Status</th>
                <th>Start time</th>
                <th>End time</th>
                <th>Duration</th>
                <th>Status</th>
                <th>Status</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(group, index) in filteredGroups"
                :key="`${group.policy}_${group.workflow}`"
              >
                <td>
                  <span v-show="index === 0 || filteredGroups[index -1].policy !== group.policy">
                    <strong>{{ group.policy }}</strong>
                  </span>
                </td>
                <td>
                  <b-link :to="{ name: 'networker-detail.workflow-detail', params: { workflowName: group.workflow }, query: { group: group.group, policy: group.policy, from: $moment(timeRange.startDate).format('YYYY-MM-DD'), to: $moment(timeRange.endDate).format('YYYY-MM-DD'), onlyErrors: onlyErrors} }">
                    {{ group.workflow }}
                  </b-link>
                </td>
                <td>
                  <JobStatusIcon :status="group.lastJob.calculatedJobStatus" />
                </td>
                <td>{{ group.lastJob.start | formatDateTimeTz(asup.timezone) }}</td>
                <td>{{ group.lastJob.end | formatDateTimeTz(asup.timezone) }}</td>
                <td>{{ group.lastJob.duration | formatClrTimeSpan }}</td>
                <td>
                  <b-badge
                    v-if="group.succeededWfJobs.length > 0"
                    v-b-popover.hover.v-success.html="getWfJobPopoverContentHtml(group.succeededWfJobs)"
                    title="Last 5 succeeded jobs"
                    variant="success"
                  >
                    {{ group.succeededWfJobs.length }}
                  </b-badge>

                  <b-badge
                    v-if="group.warningWfJobs.length > 0"
                    v-b-popover.hover.v-warning.html="getWfJobPopoverContentHtml(group.warningWfJobs)"
                    title="Last 5 jobs with warning"
                    variant="warning"
                    class="ml-1"
                  >
                    {{ group.warningWfJobs.length }}
                  </b-badge>

                  <b-badge
                    v-if="group.failedWfJobs.length > 0"
                    v-b-popover.hover.v-danger.html="getWfJobPopoverContentHtml(group.failedWfJobs)"
                    title="Last 5 failed jobs"
                    variant="danger"
                    class="ml-1"
                  >
                    {{ group.failedWfJobs.length }}
                  </b-badge>

                  <b-badge
                    v-if="group.unknownWfJobs.length > 0"
                    v-b-popover.hover.v-secondary.html="getWfJobPopoverContentHtml(group.unknownWfJobs)"
                    title="Last 5 jobs with unknown status"
                    variant="secondary"
                    class="ml-1"
                  >
                    {{ group.unknownWfJobs.length }}
                  </b-badge>
                </td>
                <td>
                  <b-badge
                    v-if="group.succeededClients.length > 0"
                    v-b-popover.hover.v-successs.html="getClientPopoverContentHtml(group.succeededClients)"
                    title="Top 10 succeeded clients"
                    variant="success"
                  >
                    {{ group.succeededClients.length }}
                  </b-badge>

                  <b-badge
                    v-if="group.warningClients.length > 0"
                    v-b-popover.hover.v-warning.html="getClientPopoverContentHtml(group.warningClients)"
                    title="Top 10 clients with warning"
                    variant="warning"
                    class="ml-1"
                  >
                    {{ group.warningClients.length }}
                  </b-badge>

                  <b-badge
                    v-if="group.failedClients.length > 0"
                    v-b-popover.hover.v-danger.html="getClientPopoverContentHtml(group.failedClients)"
                    title="Top 10 failed clients"
                    variant="danger"
                    class="ml-1"
                  >
                    {{ group.failedClients.length }}
                  </b-badge>
                </td>
                <td>
                  <b-badge
                    v-if="group.succeededCloneJobCount > 0"
                    variant="success"
                  >
                    {{ group.succeededCloneJobCount }}
                  </b-badge>

                  <b-badge
                    v-if="group.warningCloneJobCount > 0"
                    class="ml-1"
                    variant="warning"
                  >
                    {{ group.warningCloneJobCount }}
                  </b-badge>

                  <b-badge
                    v-if="group.failedCloneJobCount > 0"
                    class="ml-1"
                    variant="danger"
                  >
                    {{ group.failedCloneJobCount }}
                  </b-badge>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard, VBPopover, BFormCheckbox, BForm, BFormGroup, BOverlay, BBadge, BLink,
} from 'bootstrap-vue'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import JobStatusIcon from '@/views/asup/backup-software/components/JobStatusIcon.vue'
import NetWorkerService from '@/service/networker.service'
import moment from '@/libs/moment'

export default {
  components: {
    BCard,
    BFormCheckbox,
    BForm,
    BFormGroup,
    BOverlay,
    BBadge,
    BLink,
    DateRangePicker,
    JobStatusIcon,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      timeRange: {
        range: 'last7Days',
      },
      isJobsLoading: false,
      jobs: [],
      onlyErrors: false,
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
    sortedJobs() {
      return this.jobs
        .concat()
        .sort((a, b) => (a.start > b.start ? -1 : 1))
    },
    filteredGroups() {
      if (this.onlyErrors === true) {
        return this.groups.filter(x => x.warningWfJobs.length > 0
          || x.failedWfJobs.length > 0
          || x.unknownWfJobs.length > 0
          || x.activeWfJobs.length > 0)
      }

      return this.groups
    },
    groups() {
      return this.jobs.reduce((arr, job) => {
        const { policy, workflow } = job

        // create groups by policy and workflow
        let group = arr.find(x => x.policy === policy && x.workflow === workflow)
        if (!group) {
          group = {
            policy,
            workflow,
            group: job.group,
            lastJob: job,
            activeWfJobs: [],
            unknownWfJobs: [],
            succeededWfJobs: [],
            warningWfJobs: [],
            failedWfJobs: [],
            succeededClients: [],
            warningClients: [],
            failedClients: [],
            succeededCloneJobCount: 0,
            warningCloneJobCount: 0,
            failedCloneJobCount: 0,
          }

          arr.push(group)
        }

        // assign last job
        if (moment(job.start) > moment(group.lastJob.start)) {
          group.lastJob = job
        }

        // assign job by status
        switch (job.calculatedJobStatus) {
          case 0:
            group.succeededWfJobs.push(job)
            break
          case 10:
            group.activeWfJobs.push(job)
            break
          case 20:
            group.warningWfJobs.push(job)
            break
          case 30:
            group.failedWfJobs.push(job)
            break
          case null:
            group.unknownWfJobs.push(job)
            break
          default:
            break
        }

        // assign clients by status
        group.succeededClients = [...new Set([...group.succeededClients, ...job.succeededClients])] // merge arrays and remove duplicates (with Set)
        group.warningClients = [...new Set([...group.warningClients, ...job.warningClients])] // merge arrays and remove duplicates (with Set)
        group.failedClients = [...new Set([...group.failedClients, ...job.failedClients])] // merge arrays and remove duplicates (with Set)

        // count clone job by status
        group.succeededCloneJobCount += job.cloneJobSuccessCount
        group.warningCloneJobCount += job.cloneJobWarningCount
        group.failedCloneJobCount += job.cloneJobFailedCount

        return arr
      }, [])
        .sort((a, b) => {
          if (a.policy > b.policy) {
            return 1
          }
          if (a.policy < b.policy) {
            return -1
          }

          if (a.workflow > b.workflow) {
            return 1
          }

          return -1
        })
    },
  },
  beforeMount() {
    if (this.$route.query.from && this.$route.query.to) {
      this.timeRange = {
        startDate: moment(this.$route.query.from).toDate(),
        endDate: moment(this.$route.query.to).toDate(),
      }
    }

    if (this.$route.query.onlyErrors === true) {
      this.onlyErrors = true
    } else if (this.isCurrentUserHost === true) { // Set onlyErrors filter for host users automatically, if not explicitly set in query
      this.onlyErrors = true
    }

    // Watch properties after setting timer range from query to avoid loading data too many times
    this.$watch('timeRange', this.loadJobs)
    this.$watch('onlyErrors', this.loadJobs)
  },
  mounted() {
    this.loadJobs()
  },
  methods: {
    loadJobs() {
      this.isJobsLoading = true
      NetWorkerService.getWorkflowJobListAsync(this.asup.id, {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
        // status: this.statusFilter, // we want to have all jobs in time range and filter status then on client side. otherwise the last job is not correct.
      }, { disableTenantFilter: true })
        .then(result => {
          this.jobs = result.items
        })
        .finally(() => {
          this.isJobsLoading = false
        })
    },
    getWfJobPopoverContentHtml(wfJobs) {
      const lastItems = wfJobs.sort((a, b) => (this.$moment(a.start) > this.$moment(b.start) ? -1 : 1)).slice(0, 5)

      let listItemsHtml = ''
      for (let i = 0; i < lastItems.length; i += 1) {
        listItemsHtml += `<li>${this.$options.filters.formatDateTimeTz(lastItems[i].start, this.asup.timezone)}</li>`
      }

      return `<ul>${listItemsHtml}</ul>`
    },
    getClientPopoverContentHtml(clients) {
      const sortedClients = clients.sort((a, b) => (a > b ? 1 : -1)).slice(0, 10)

      let listItemsHtml = ''
      for (let i = 0; i < sortedClients.length; i += 1) {
        listItemsHtml += `<li>${sortedClients[i]}</li>`
      }

      return `<ul>${listItemsHtml}</ul>`
    },
  },
}
</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
